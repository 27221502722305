var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"show-select":"","disable-sort":"","disable-filtering":"","fixed-header":"","item-key":"taxNumber","loading":_vm.loading,"headers":_vm.headers,"server-items-length":_vm.total,"items-per-page":_vm.perPage,"page":_vm.page,"items":_vm.computedItems,"footer-props":{ 'items-per-page-options': [5, 10, 25, 50, 100] }},on:{"update:items-per-page":function($event){return _vm.$emit('update:per-page', $event)},"update:page":function($event){return _vm.$emit('update:page', $event)}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":"green","ripple":false,"value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
var isMobile = ref.isMobile;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-row',{staticClass:"py-1",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"d-none d-sm-inline-block",attrs:{"left":"","size":"36"}},[_vm._v(" "+_vm._s(item.taxNumber.length > 11 ? "mdi-office-building" : "mdi-account-tie")+" ")])],1),_c('v-col',[_c('span',{staticClass:"font-weight-medium text-subtitle-2",domProps:{"textContent":_vm._s(item.fiscalName || item.name)}}),(item.fiscalName)?_c('span',{staticClass:"secondary--text text--lighten-2"},[_vm._v(" — "+_vm._s(item.name)+" ")]):_vm._e(),_c('div',{staticClass:"d-block"},[_c('cpf-cnpj-field',{attrs:{"text":"","value":item.taxNumber}})],1)]),(!isMobile)?_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","target":"_blank","to":_vm.viewHref(item)},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-launch")])],1)]}}],null,true)},[_c('span',[_vm._v("Abrir em nova aba")])])],1):_vm._e()],1)]}}],null,true)})]}},{key:"item.contact",fn:function(ref){
var item = ref.item;
return [(item.phone)?_c('div',[_c('v-icon',{attrs:{"left":"","size":"20","color":"secondary lighten-4"}},[_vm._v(" mdi-"+_vm._s(item.whatsapp ? "whatsapp" : "phone")+" ")]),_c('a',{staticClass:"text-decoration-none",attrs:{"href":item.phoneHref,"target":item.whatsapp && '_blank'},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(_vm._f("phone")(item.phone)))])],1):_vm._e(),(item.email)?_c('div',[_c('v-icon',{attrs:{"left":"","size":"20","color":"secondary lighten-4"}},[_vm._v("mdi-email")]),_c('a',{staticClass:"text-decoration-none",attrs:{"href":'mailto:' + item.email},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(item.email))])],1):_vm._e()]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }