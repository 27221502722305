<template>
  <v-container class="mt-4" :fluid="$vuetify.breakpoint.mdOnly">
    <div
      class="d-flex flex-column flex-sm-row flex-wrap mt-n3 pt-3 pb-sm-3 toolbar"
    >
      <v-btn
        class="mr-lg-auto mr-sm-4"
        color="success"
        to="/cadastros/clientes/novo"
        :bottom="$vuetify.breakpoint.xsOnly"
        :fab="$vuetify.breakpoint.xsOnly"
        :fixed="$vuetify.breakpoint.xsOnly"
        :large="!$vuetify.breakpoint.xsOnly"
        :right="$vuetify.breakpoint.xsOnly"
        v-show="selectedItems.length === 0"
      >
        <v-icon :left="!$vuetify.breakpoint.xsOnly">mdi-plus-thick</v-icon>
        <span class="d-none d-sm-flex">Novo cliente</span>
      </v-btn>

      <div v-show="selectedItems.length > 0" class="mr-lg-auto mr-sm-4" />

      <div class="mb-4 flex-grow-1 flex-lg-grow-0">
        <search-field
          clearable
          label="Nome ou documento"
          v-model="filterBySearch"
        />
      </div>
    </div>

    <v-row>
      <v-col cols="12">
        <customer-table
          :items="items"
          :loading="loading"
          :page.sync="page"
          :per-page.sync="perPage"
          :total="total"
          v-model="selectedItems"
          @click:row="rowClicked"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerTable from "@/components/Customer/Table";
import SearchField from "@/components/SearchField";

export default {
  name: "RecordsProductList",
  metaInfo() {
    return {
      title: "Produtos",
    };
  },
  components: {
    CustomerTable,
    SearchField,
  },
  data: () => ({
    filterBySearch: "",
    items: [],
    loading: true,
    requesting: true,
    page: 1,
    perPage: 25,
    prevCursor: undefined,
    nextCursor: undefined,
    selectedItems: [],
    total: 0,
  }),
  watch: {
    filterBySearch() {
      this.reloadCustomers();
    },
    perPage() {
      this.reloadCustomers();
    },
    page(newValue, oldValue) {
      if (newValue > oldValue) {
        this.reloadCustomers(this.nextCursor);
      } else if (newValue < oldValue) {
        this.reloadCustomers(this.prevCursor);
      }
    },
  },
  mounted() {
    this.loadCustomers();
  },
  methods: {
    reloadCustomers(cursor) {
      if (this.requesting) {
        clearTimeout(this.requesting);
      }

      this.requesting = setTimeout(() => {
        this.loadCustomers(cursor);
      }, 300);
    },
    async loadCustomers(cursor) {
      this.loading = true;
      try {
        let params = {
          per_page: this.perPage,
          q: this.filterBySearch || undefined,
          cursor: cursor || undefined,
        };
        const { data } = await this.$http.get("/v1/customers", { params });
        this.total = data.total;
        this.prevCursor = data.prev_cursor;
        this.nextCursor = data.next_cursor;
        this.items = data.items.map((c) => ({
          ...c,
          taxNumber: c.tax_id,
          fiscalName: c.fiscal_name,
        }));
      } catch (err) {
        this.$sentry.captureException(err);
        this.notifyError("Ocorreu um erro ao carregar lista de Clientes 😢");
      }
      this.loading = false;
    },
    rowClicked(item) {
      this.$router.push({
        name: "customer-view",
        params: { id: item.taxNumber },
      });
    },
  },
};
</script>
