<template>
  <v-data-table
    show-select
    disable-sort
    disable-filtering
    fixed-header
    item-key="taxNumber"
    :loading="loading"
    :headers="headers"
    :server-items-length="total"
    :items-per-page="perPage"
    :page="page"
    :items="computedItems"
    :footer-props="{ 'items-per-page-options': [5, 10, 25, 50, 100] }"
    @update:items-per-page="$emit('update:per-page', $event)"
    @update:page="$emit('update:page', $event)"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:item.data-table-select="{ isSelected, select }">
      <v-simple-checkbox
        color="green"
        :ripple="false"
        :value="isSelected"
        @input="select($event)"
      />
    </template>

    <template v-slot:item.name="{ item, isMobile }">
      <v-hover v-slot="{ hover }">
        <v-row no-gutters align="center" class="py-1">
          <v-col cols="auto">
            <v-icon left size="36" class="d-none d-sm-inline-block">
              {{
                item.taxNumber.length > 11
                  ? "mdi-office-building"
                  : "mdi-account-tie"
              }}
            </v-icon>
          </v-col>

          <v-col>
            <span
              class="font-weight-medium text-subtitle-2"
              v-text="item.fiscalName || item.name"
            />
            <span
              v-if="item.fiscalName"
              class="secondary--text text--lighten-2"
            >
              &mdash; {{ item.name }}
            </span>

            <div class="d-block">
              <cpf-cnpj-field text :value="item.taxNumber" />
            </div>
          </v-col>

          <v-col v-if="!isMobile" v-show="hover" cols="auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  target="_blank"
                  :to="viewHref(item)"
                  @click.stop
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small>mdi-launch</v-icon>
                </v-btn>
              </template>

              <span>Abrir em nova aba</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-hover>
    </template>

    <template v-slot:item.contact="{ item }">
      <div v-if="item.phone">
        <v-icon left size="20" color="secondary lighten-4">
          mdi-{{ item.whatsapp ? "whatsapp" : "phone" }}
        </v-icon>
        <a
          class="text-decoration-none"
          :href="item.phoneHref"
          :target="item.whatsapp && '_blank'"
          @click.stop
          >{{ item.phone | phone }}</a
        >
      </div>

      <div v-if="item.email">
        <v-icon left size="20" color="secondary lighten-4">mdi-email</v-icon>
        <a
          class="text-decoration-none"
          :href="'mailto:' + item.email"
          @click.stop
          >{{ item.email }}</a
        >
      </div>
    </template>
  </v-data-table>
</template>

<script>
import FormatterFilter from "@/mixins/FormatterFilter";
import CpfCnpjField from "@/components/CpfCnpjField";

export default {
  name: "CustomerTable",
  mixins: [FormatterFilter],
  components: {
    CpfCnpjField,
  },
  props: {
    loading: Boolean,
    total: Number,
    perPage: Number,
    page: Number,
    items: Array,
  },
  computed: {
    headers() {
      return [
        {
          text: "Nome do cliente",
          value: "name",
        },
        {
          text: "Contato",
          value: "contact",
          width: 300,
        },
      ];
    },
    computedItems() {
      return this.items.map((customer) => {
        const { phone } = customer;
        const intPhone = this.internationalPhone(phone);

        var whatsappNumber = this.whatsappNumber(intPhone);
        var phoneHref;

        if (whatsappNumber) {
          var text = `Olá ${customer.fiscalName || customer.name}.`;
          phoneHref = `https://wa.me/${intPhone.substring(1)}?text=${text}`;
        } else {
          phoneHref = `tel:${intPhone}`;
        }

        return {
          ...customer,
          whatsapp: !!whatsappNumber,
          phoneHref,
        };
      });
    },
  },
  methods: {
    viewHref(item) {
      return {
        name: "customer-view",
        params: { id: item.taxNumber },
      };
    },
    internationalPhone(phone) {
      if (phone.startsWith("+")) return phone;
      if (phone.length <= 11) return "+55" + phone;
      return "+" + phone;
    },
    whatsappNumber(phone) {
      if (!phone.startsWith("+55")) return;

      let firstDigit = phone.substring(5, 6);
      if (firstDigit == "9" || firstDigit == "8") {
        return phone.substring(1);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.theme--light.v-data-table::v-deep
  .v-data-table__wrapper
    table
      tbody
        tr:hover
          cursor: pointer
</style>
